import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import projectData from '../assets/projectsData';
import './projectDetail.css';
import { MdKeyboardArrowRight } from 'react-icons/md';
import ProjectsSubMenu from '../components/projectsSubMenu';
import ProjectsSubMenuSmall from '../components/projectsSubMenuSmall';
import SvgDraw from '../components/SvgDraw';

function ListItem(props) {
  const [checked, setChecked] = useState(false);
  // useEffect(() => {
  //   setChecked(false);
  //   console.log('props.navclicked', props.navclicked);
  //   console.log('checked', checked);
  // }, [props.navclicked]);
  console.log('checked', checked);
  return (
    <li
      className='sub-subheading'
      onClick={() => {
        setChecked(!checked);
      }}
    >
      {props.item.lessonTitle}
      {checked ? (
        <div>
          <ul>
            {props.item.objectives.map((objective, index) => {
              return (
                <li key={index} className='objectives'>
                  <MdKeyboardArrowRight />
                  {objective}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </li>
  );
}

function List(props) {
  return (
    <ul className='subHeading'>
      {props.projectData.map((item, index) => {
        return (
          <ListItem key={index} item={item} navclicked={props.navclicked} />
        );
      })}
    </ul>
  );
}
function ProjectDetail(props) {
  const params = useParams();
  const index = params.id - 1;
  const ipath = projectData[index].image;
  const [navclicked, setNavclicked] = useState(false);
  return (
    <div className='projectDetail-wrapper'>
      <div className='small-menu'>
        <ProjectsSubMenuSmall id={index} projectData={projectData} />
      </div>
      <div className='left'>
        <ProjectsSubMenu
          id={index}
          projectData={projectData}
          setNavclicked={setNavclicked}
        />
      </div>

      <div className='right '>
        {/* -------------image --------------*/}
        <div>
          {<h2 className='projTitle'> {projectData[index].title2} </h2>}
          <img
            // src={window.location.origin + ipath}
            src={ipath}
            className='projImg'
            alt={'projImg' + (index + 1)}
          />
        </div>
        <div>
          <table className=' summaryTable item-wrapper '>
            <tbody>
              <tr>
                <td className='summaryTableHeading'>Product</td>
                <td className='summaryTableHDetail'>
                  {projectData[index].product}
                </td>
              </tr>
              {projectData[index].ePMBusinessProcess !== '' && (
                <tr>
                  <td className='summaryTableHeading'>EPM Business Process</td>
                  <td className='summaryTableHDetail'>
                    {projectData[index].ePMBusinessProcess}
                  </td>
                </tr>
              )}
              <tr>
                <td className='summaryTableHeading'> Project </td>
                <td className='summaryTableHDetail'>
                  {' '}
                  {projectData[index].project}{' '}
                </td>
              </tr>
              <tr>
                <td className='summaryTableHeading'> Client </td>
                <td className='summaryTableHDetail'>
                  {' '}
                  {projectData[index].client}{' '}
                </td>
              </tr>
              <tr>
                <td className='summaryTableHeading'>Deliverables</td>
                <td
                  className='summaryTableHDetail'
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {projectData[index].deliverables}
                </td>
              </tr>
            </tbody>
          </table>
          {/* {---- circle data---} */}
          <div className='svg-container'>
            <div className=' svg-item'>
              <SvgDraw
                colour='#e932a6'
                noOfSlides={projectData[index].noOfLessons}
                heading='No. of Lessons'
              />
            </div>
            <div className=' svg-item'>
              <SvgDraw
                colour='green'
                noOfSlides={projectData[index].noOfSlides}
                heading='No. of Slides'
              />
            </div>
            {projectData[index].noOfLabActivities !== '' && (
              <div className='  svg-item'>
                <SvgDraw
                  colour='#7420d5'
                  noOfSlides={projectData[index].noOfLabActivities}
                  heading='No. of Lab Activities'
                />
              </div>
            )}
          </div>
        </div>
        {/* ----------------------lesson titles----------------------- */}
        <div className='item-wrapper'>
          <div className='lesson-titles'>
            <h2 className=''>Lessons</h2>
            <p>(Click on a lesson to view its Objectives)</p>

            {
              <List
                projectData={projectData[index].lessonDetails}
                navclicked={navclicked}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;
