import React, { useState, useEffect } from 'react';

import './ContactForm.css';

import emailjs from 'emailjs-com';
import validateInfo from './validateInfo';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    to_name: 'Mr. Ryde',
    from_name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationOutput = validateInfo(formData);

  function changeHandler(event) {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function callback() {
    let userId = 'user_roHoYfNVIxcqxt92xVd0l';
    let templateId = 'template_1wtjfvt';
    let recipientEmail = 'radhikabhuth@gmail.com';
    emailjs
      .send(recipientEmail, templateId, formData, userId)

      .then((response) => {
        console.log('You Successfully Sent an Email!');
        setFormData((prevState) => ({
          ...prevState,
          from_name: '',
          email: '',
          message: '',
        }));
        setIsSubmitting(false);
        console.log('form data erased', formData);
      })
      .catch((error) => console.error('Looks like something went wrong'));
  }
  function handleSubmit(event) {
    event.preventDefault();

    setErrors(validationOutput.errors);
    setIsSubmitting(validationOutput.allisfine);
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);
  return (
    <form action='' method='POST' autoComplete='off'>
      <div className='item-wrapper '>
        <div className='form-inputs'>
          <input
            type='text'
            name='from_name'
            className='input-txt'
            id='name'
            placeholder='Full Name'
            required
            onChange={changeHandler}
            value={formData.from_name == null ? '' : formData.from_name}
          />
          {errors.name && <p>{errors.name}</p>}
        </div>
        <div className='form-inputs'>
          <input
            type='email'
            name='email'
            className='input-txt '
            id='mail'
            placeholder='Email '
            required
            onChange={changeHandler}
            value={formData.email == null ? '' : formData.email}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-inputs'>
          <textarea
            name='message'
            type='text'
            rows='5'
            className='input-txt'
            id='subject'
            placeholder='Type your message here'
            required
            onChange={changeHandler}
            value={formData.message == null ? '' : formData.message}
          ></textarea>
          {errors.message && <p>{errors.message}</p>}
        </div>
        <button
          className={`submit-btn ${isSubmitting ? 'nohvr' : ''}`}
          type='submit'
          name='submit-btn'
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Send
        </button>
        {isSubmitting && (
          <p className='ack'>
            <br />
            <br />
            Thank you. We will get back to you soon.
          </p>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
