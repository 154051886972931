import React from 'react';
import Notices from './notices';
import noticesData from '../assets/noticesData.js';
const Advert = (props) => {
  return (
    <div className='side-col-wrapper'>
      <div>
        <div className='hiddentitle'>
          <h2 className='centre-title'>Check this out!</h2>
          <h4 className='subtitle'>Current classes</h4>
        </div>
      </div>
      {noticesData.map((item) => {
        return (
          <Notices
            title={item.title}
            facilitator={item.facilitator}
            regLink={item.registrationLink}
            sylLink={item.syllabusLink}
            item={item}
          />
        );
      })}
    </div>
  );
};

export default Advert;
