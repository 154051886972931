import React from 'react';
import './HomeLayout.css';
import CarousalHeader from '../AliceCarousel';
import ShowProjects from '../showProjects';
import Advert from '../advert';

import projectsData from '../../assets/projectsData.js';
import Team from '../Team';

function HomeLayout(props) {
  return (
    <div>
      <CarousalHeader />
      <div className='central-wrapper'>
        <div className='main-col'>
          <ShowProjects projectData={projectsData} />
        </div>
        <div className='side-col'>
          <Advert />
        </div>
      </div>
      <div className='central-wrapper'>
        <Team />
      </div>
    </div>
  );
}
export default HomeLayout;
