import React, { useState } from 'react';
import PlaneImg from '../images/contact/plane.svg';
import location from '../images/contact/location.svg';
import { AiOutlineMail } from 'react-icons/ai';
import './contact.css';
import ContactForm from '../components/forms/ContactForm';
import emailjs from 'emailjs-com';

const Contact = () => {
  return (
    <div className='central-wrapper'>
      <div className='left-col'>
        <h3 className='title'>Get In Touch</h3>
        <ul className=''>
          <li className=' '>
            <img
              className='mr-2'
              alt='contacticon'
              width='20px'
              src={location}
            ></img>
            <br></br>
            No 5, Panch Tarani, <br></br>Balaji Nager,<br></br> Padikuppam Road,
            <br></br> AnnaNager West, <br></br>
            Chennai - 600040
          </li>

          <li className='address'>
            <AiOutlineMail className='mr-2' />
            {} enquiry@rydecs.com
          </li>
        </ul>

        <img
          src={PlaneImg}
          className='img-fluid'
          alt='plane'
          height='250px'
          width='250px'
        ></img>
      </div>
      <div className='right-col'>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
