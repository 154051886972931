import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import teamData from '../assets/teamData';
import './teamDetail.css';

import { Link } from 'react-router-dom';

let selectedTeamDetail = [];
function memberDetail(id) {
  selectedTeamDetail = teamData.find((item) => {
    return item.name_id === id;
  });
}
const Detail = (arry) => {
  return (
    <div className=''>
      <table className='paddingBetweenCols'>
        {arry.ar.map((item) => {
          return (
            <tr>
              <td className='date-style'>{item[0]}</td>
              <td className=''>
                <div>
                  <div className='position'>{item[1]}</div>
                  <div className='company'>{item[2]}</div>
                  <div className='work'>{item[3]}</div>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
const DetailSolomon = (arry) => {
  return (
    <div className='solomon-exp'>
      <div>{arry.ar[0]}</div>
      <ul>
        {arry.ar[1].map((item) => {
          return <li className='sub-title'>{item}</li>;
        })}
      </ul>
    </div>
  );
};
function TeamDetail(props) {
  const params = useParams();
  const member = params.id;
  //const [teamDetails, setTeamDetail] = useState();
  memberDetail(member);

  return (
    <div className='teamDetail-wrapper'>
      <div className='left-side'>
        <div>
          <Link to={{ pathname: '/home' }}>
            <button className='back-button'>Back to Team</button>
          </Link>
        </div>
        <img className='img-size' src={selectedTeamDetail.imageName}></img>
      </div>
      <div className='right-side'>
        <div className='header-right'>
          <div>
            <h1>{selectedTeamDetail.name}</h1>
            <h3>{selectedTeamDetail.currentTitle}</h3>
          </div>
        </div>
        <br></br>
        <h3>Experience </h3>
        <hr className='blueline'></hr>
        {member === 'solomon' && <h4>{selectedTeamDetail.experienceTotal}</h4>}
        {member === 'solomon' && (
          <DetailSolomon ar={selectedTeamDetail.experienceDetail} />
        )}
        {member !== 'solomon' && (
          <Detail ar={selectedTeamDetail.experienceDetail} />
        )}

        <h3>Education</h3>
        <hr className='blueline'></hr>
        {<Detail ar={selectedTeamDetail.education} />}
      </div>
      <div></div>
    </div>
  );
}

export default TeamDetail;
