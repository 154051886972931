import React from 'react';
import './notices.css';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Syllabus from './syllabus';

const Notices = (props) => {
  return (
    <div className='notice-item-card '>
      <div className='notice-item'>
        <div className='notice-heading'>
          <h1>{props.title}</h1>
          <hr></hr>
        </div>
        <div className='notice-content'>
          <h3>
            {' '}
            3 months <span style={{ fontSize: '2rem', color: 'grey' }}>
              |
            </span>{' '}
            3 Days a Week{' '}
          </h3>
          <h4>4pm - 6pm or 6pm - 8pm</h4>
          <div className='small-text-center'>
            Attend Online or from Ponneri center
          </div>
          <div className='middle-txt'>
            <div>
              <br></br>
              <div className='middle-big'>
                <div className='big-text'> FREE </div>
                <div className='small-text'>for College students</div>
              </div>
              <div className='middle-big'>
                <div className='big-text'> ₹500 </div>
                <div className='small-text'>for Working professionals</div>
              </div>
            </div>
            <div className='tutor'>
              <h4>
                <span style={{ fontSize: '1rem', color: 'grey' }}>
                  Facilitator
                </span>{' '}
                <span style={{ fontSize: '1.8rem' }}>{props.facilitator}</span>
              </h4>
            </div>
          </div>
          <div className='links-div'>
            <a className='link-txt' href={props.regLink}>
              Register |
            </a>
            <Link
              to={{ pathname: `/classes/${props.sylLink}` }}
              className='link-txt'
            >
              | View detailed syllabus
              {/* <Syllabus detail={props} /> */}
            </Link>
            {/* <a className='link-txt' href={props.sylLink}>
              {' '}
              View detailed syllabus
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notices;
