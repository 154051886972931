import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import noticesData from '../assets/noticesData';
import './syllabus.css';
import {
  MdKeyboardArrowRight,
  MdArrowForward,
  MdArrowBack,
} from 'react-icons/md';
var result;
var currentIndex;
var nxtSyl;
var prvSyl;
function courseDetail(id) {
  result = noticesData.find((obj) => {
    return obj.syllabusLink === id;
  });
  currentIndex = noticesData.findIndex((obj) => {
    return obj.syllabusLink === id;
  });
  if (currentIndex < 4) nxtSyl = noticesData[currentIndex + 1].syllabusLink;
  if (currentIndex > 0) prvSyl = noticesData[currentIndex - 1].syllabusLink;
  console.log(result, currentIndex, nxtSyl, prvSyl);
}
function layoutTopics(topics, index) {
  return (
    <ul>
      {topics.map((topic, index2) => {
        console.log(index2, topic);
        return (
          <li key={index2} className='topic-layout'>
            {<MdKeyboardArrowRight />}
            {topic}
          </li>
        );
      })}
    </ul>
  );
}
const Syllabus = (props) => {
  const params = useParams();
  const courseId = params.id;
  courseDetail(params.id);
  console.log('regLink', result.registrationLink);
  return (
    <div className='course '>
      <div className='notice-item'>
        <div className='syllabus-heading-div'>
          <div className='arrows'>
            <Link to={{ pathname: `/classes/${prvSyl}` }} className='link-txt'>
              {currentIndex > 0 ? (
                <span>
                  <MdArrowBack />
                  <span className='btn-vis'>Previous Course</span>
                </span>
              ) : (
                ''
              )}
            </Link>
          </div>

          <div className='syllabus-heading'>{result.title}</div>
          <div className='arrows'>
            <Link to={{ pathname: `/classes/${nxtSyl}` }} className='link-txt'>
              {currentIndex < 4 ? (
                <span>
                  <span className='btn-vis'> Next Course</span>
                  <MdArrowForward />{' '}
                </span>
              ) : (
                ''
              )}
            </Link>
          </div>
        </div>
        <hr></hr>
        <div className='notice-content'>
          <h3>
            3 months <span style={{ fontSize: '2rem', color: 'grey' }}>|</span>3
            Days a Week{' '}
          </h3>
          <h4>4pm - 6pm or 6pm - 8pm</h4>
          <div className='small-text-center'>
            Attend Online or from Ponneri center
          </div>
        </div>
        <div className='middling'>
          <div className='text-text '>
            <p>
              <span className='big-text'>FREE </span>
              <span className='big-text'></span>for College students
            </p>
            <p>
              <span className='big-text'> ₹500 </span>for Working professionals
            </p>
          </div>
          <div className='right-right'>
            <p>
              <span style={{ fontSize: '1.5rem', color: 'grey' }}>
                Facilitator
              </span>{' '}
              <span style={{ fontSize: '2.2em' }}>{result.facilitator}</span>
            </p>
          </div>
        </div>
        <div style={{ fontSize: '1.5rem' }}>
          <p>
            Students will also get a Participation and Completion Certificate
            from the Ryde foundation.
          </p>
        </div>
        <div className=''>
          <a className='link-txt' href={result.registrationLink}>
            <span style={{ fontSize: '2rem' }}>Register Now</span>
          </a>
        </div>

        <div className='course-item-card '>
          <span style={{ fontSize: '1.5rem', color: 'grey' }}>Syllabus</span>
          <ul>
            {result.syllabus.map((item, index) => {
              return (
                <li key={index} className='heading-layout'>
                  {item.heading}

                  {item.topics.length !== 0 ? layoutTopics(item.topics) : ''}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Syllabus;
