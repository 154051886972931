import React from 'react';
import './SvgDraw.css';

function SvgDraw(props) {
  return (
    <div className='svg-style'>
      <svg viewBox='0 0 300 300'>
        <circle
          cx='50%'
          cy='50%'
          r='50'
          stroke={props.colour}
          strokeWidth='22'
          fill='white'
        />
        <text
          x='50%'
          y='50%'
          textAnchor='middle'
          fill='black'
          fontSize='30px'
          fontFamily='Arial'
          dy='.3em'
        >
          {props.noOfSlides}
        </text>
      </svg>
      <h3 className='txt'>{props.heading}</h3>
    </div>
  );
}

export default SvgDraw;
