export default function validateInfo(values) {
  let errors = {};
  console.log('called', errors);
  let name = values.from_name;
  let message = values.message;
  let messageisfine = false;
  let nameisfine = false;
  let emailisfine = false;
  let allisfine = false;
  if (name == null || !name.trim()) {
    errors.name = 'Name is required';
  } else if (name.trim().length < 2) {
    errors.name = 'Your name should be atleeast two characters long';
  } else {
    nameisfine = true;
  }
  if (message == null || !message.trim()) {
    errors.message = 'Message is required';
  } else if (message.trim().length < 2) {
    errors.message = 'Your message is too short';
  } else {
    emailisfine = true;
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
  ) {
    errors.email = 'Email address is invalid';
  } else {
    messageisfine = true;
  }
  if (messageisfine && emailisfine && nameisfine) {
    allisfine = true;
  }
  return { errors, allisfine };
}
