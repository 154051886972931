import React from "react";

function About(props) {
    return (
        <div>
           <h2>About</h2>
        </div>
    );
}

export default About;