import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

import './MainNavigation.css';
import { useState } from 'react';
import logo from '../../images/logo.svg';
const MainNavigation = () => {
  const [showLinks, setShowLinks] = useState(false);
  return (
    <header className='header'>
      <NavLink
        to='/home'
        activeClassName='active'
        onClick={() => setShowLinks(false)}
      >
        <div className='logo-item'>
          <img src={logo} className='filter-invert ' />
          <h3 className='logo'>Ryde</h3>
        </div>
      </NavLink>

      <nav className='nav'>
        <ul
          className={showLinks ? 'show' : ''}
          onClick={() => setShowLinks(!showLinks)}
        >
          <NavLink to='/home' activeClassName='active'>
            <li>Home</li>
          </NavLink>

          <NavLink
            to={{
              pathname: '/projectinfo/1',
            }}
            activeClassName='active'
          >
            <li>Projects</li>
          </NavLink>

          {/* <NavLink to='/team/1' activeClassName='active'>
            <li>Team</li>
          </NavLink>

          <NavLink to='/about' activeClassName='active'>
            <li>About</li>
          </NavLink> */}

          <NavLink to='/contact' activeClassName='active'>
            <li>Contact</li>
          </NavLink>
        </ul>
        <div onClick={() => setShowLinks(!showLinks)}>
          <FaBars className='bars' />
        </div>
      </nav>
      {/* </div> */}
    </header>
  );
};

export default MainNavigation;
