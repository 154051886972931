import React from 'react';
import { Link } from 'react-router-dom';
import teamData from '../assets/teamData.js';
import {
  solomon_img,
  joshua_img,
  hannah_img,
  rajalakshmi_img,
} from '../images/index';

import './Team.css';
const team_array = ['solomon', 'joshua', 'hannah', 'rajalakshmi'];
const img_array = [solomon_img, joshua_img, hannah_img, rajalakshmi_img];
function Team(props) {
  return (
    <div className='team-wrapper'>
      <div className='left-team'>
        <div>
          <h2>
            Meet the
            <span
              style={{ color: 'rgba(116, 32, 213, 1)', textAlign: 'center' }}
            >
              {' '}
              Team
            </span>
          </h2>
          <h4>Together we are strong</h4>
        </div>
      </div>
      <div className='right-team'>
        <div className='others'>
          {team_array.map((item, index) => {
            console.log(item + '_img');
            return (
              <Link to={{ pathname: `/team/${item}` }} className=''>
                <div className='head'>
                  {index === 0 && <div className='ceo'>CEO</div>}
                  <div className='circular-portrait'>
                    <img className='' src={img_array[index]}></img>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Team;
