const noticesData = [
  {
    title: 'Python Fundamentals',
    facilitator: 'Krishna',
    registrationLink: 'https://forms.gle/EcBqYZXchwvE2Uth6',
    syllabusLink: 'python-fundamentals-syllabus',
    syllabus: [
      { heading: 'Python Fundamentals ( 8 Weeks)', topics: [] },
      { heading: 'Python advanced ( 4 Weeks )', topics: [] },
      { heading: 'Total Duration : 3 months', topics: [] },
    ],
  },
  {
    title: 'Foundation Course on Graphic Designing',
    facilitator: 'Joshua',
    registrationLink: 'https://forms.gle/qFV59x5TD9k5kW2A6',
    syllabusLink: 'foundation-course-on-graphic-designing-syllabus',
    syllabus: [
      {
        heading: 'Photoshop 6 Sessions ( 2 weeks )',
        topics: [
          'Tools and Workspaces',
          'Layers and Components',
          'Image Editing',
          'Digital Printing',
        ],
      },
      {
        heading: 'Illustrator 3 Sessions ( 1 week )',
        topics: ['Layer Functions', 'Typography', 'Export Formats'],
      },
      {
        heading: 'Corel draw 9 Sessions (3 weeks)',
        topics: [
          'Tools and Workspaces',
          ' Logo Creation',
          'Vector Illustration',
          'Drawing',
        ],
      },
      {
        heading: ' In Design (6 sessions)',
        topics: ['Document Setup', 'Page Making', 'Export Methods'],
      },
    ],
  },

  {
    title: 'Oracle 19c SQL For Beginners',
    facilitator: 'Jayanthan Solomon',
    registrationLink: ' https://forms.gle/hTB6b2Z61CMxX1tL7',
    syllabusLink: 'oracle-19c-SQL-for-beginners',
    syllabus: [
      {
        heading: '',
        topics: [
          'Introduction to Oracle Database',
          ' Retrieving Data Using the SQL SELECT Statement',
          'Introduction to Data Definition Language',
          ' Restricting and Sorting Data from Database',
          ' Using Single-Row Functions to Customize Output',
          ' Using Conversion Functions and Conditional Expressions',
          'Reporting Aggregated Data Using the Group Functions',
          'Displaying Data from Multiple Tables Using Joins',
          'Using Subqueries to Solve Queries',
          'Using the Set Operators',
          'Managing Tables Using DML Statements',
        ],
      },
    ],
  },
  {
    title: 'Learn English',
    facilitator: 'Rajalakshmi',
    registrationLink: 'https://forms.gle/Ugm7RCEg4wrPDhQcA',
    syllabusLink: 'learn-english',
    syllabus: [
      { heading: 'Basic Grammar 6 Sessions ( 2 weeks )', topics: [] },
      { heading: 'Sentence Formation 3 Sessions ( 1 week )', topics: [] },
      { heading: 'Conversation 9 Sessions (3 weeks)', topics: [] },
      { heading: 'No. of Assignments: 6', topics: [] },
    ],
  },
  {
    title: 'Learn Aptitude Fundamentals',
    facilitator: 'XYZzzzzz',
    registrationLink: 'https://forms.gle/iJLXhv8wYaaWBCW19',
    syllabusLink: 'learn-aptitude-fundamentals-syllabus',
    syllabus: [
      {
        heading: '',
        topics: [
          'Average',
          'Number',
          'Compound Interest',
          'Partnership',
          'Problem on Ages',
          'Calendar',
          'Boats and Streams',
          'Clocks',
          'Height and Distance',
          'Percentage',
          ' Pipes and Cisterns',
          'Profit and Loss',
          'Speed and Distance',
          'Simple Interest',
          'Problem on Trains',
          'Time and Work',
          ' Alligation and Mixture',
          'Surds and Indices',
          'HCF and LCM',
          'Area',
          ' Ratio and Proportion',
          'Races and Games',
          'Probability',
          'Volume and Surface Area',
          'Decimal Fraction',
          'Simplification',
          ' Stocks and Shares',
          'Chain Rule',
          "True and Banker's Discount",
          'Logarithm',
          'Square Roots and Cube Roots',
          'Problem on Numbers',
          'Odd Man Out and Series',
          'Algebraic Expressions',
          'Permutation & Combination',
        ],
      },
    ],
  },
];
export default noticesData;
