import {
  solomon_img,
  joshua_img,
  hannah_img,
  rajalakshmi_img,
} from '../images/index';
const teamData = [
  {
    name_id: 'solomon',
    name: 'Jayanthan Solomon',
    imageName: solomon_img,
    currentTitle: 'CEO & Managing Director',
    titles: [
      'CEO & Managing Director, Ryde Consulting',
      'Rtd. Practice Director',
      'Oracle University - APAC, Oracle Corporation',
    ],
    education: [
      ['1974 - 1979', 'Bachelor of Technology in Electrical (Power)'],
      ['1982 - 1983', ' Post Graduate Diploma in Systems Management '],
      ['1983 - 1985', ' Post Graduate Diploma in Industrial Engineering '],
    ],
    experienceTotal: 'Total : 38 + years of experience ',
    experienceDetail: [
      ['Oracle Corporation - Oracle University: 20 + years '],
      [
        'Director New initiatives:  2+ years',
        'Director  - Resourcing, Delivery Logistics and Key Initiative:  2 Years',
        ' Director , Solution Architect key Accounts: 2 years',
        '  Practice Director Live Virtual class  APAC: 2 years',
        ' IBM Key Account Delivery Director: 2+ years',
        ' Regional Resource Director (APAC): 2+years',
        ' Senior Manager India Delivery: 5+ Years',
        ' Instructor and Classroom delivery support: 2+ years',
      ],
    ],
  },
  {
    name_id: 'hannah',
    name: 'Hannah (Annalakshmi) ',
    imageName: hannah_img,
    currentTitle: 'Associate Consultant',
    education: [['2017 - 2021', 'Bachelor of Technology in IT']],
    experienceTotal: ' 3 + years of experience',
    experienceDetail: [
      [
        '2021 - Present',
        'Associate Consultant',
        'Ryde Consulting',
        'Worked on "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut  ',
      ],
    ],
  },
  {
    name_id: 'joshua',
    name: 'Joshua',
    imageName: joshua_img,
    currentTitle: 'Content Developer',
    education: [
      ['2014 - 2017', 'Bachelor of Computer Science'],
      ['2017 - 2019', ' Master of Computer Science'],
    ],
    experienceTotal: '2 + years of experience',
    experienceDetail: [
      [
        '2021 - Present',
        'Content Developer ',
        'Ryde Consulting',
        'UI developer and worked with data handling...lorem..olore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut ',
      ],
      [
        '2019 - 2021',
        'UI Developer ',
        'XYZ company',
        'Worked on "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut  ',
      ],
    ],
  },
  {
    name_id: 'rajalakshmi',
    name: 'Rajalakshmi ',
    imageName: rajalakshmi_img,
    currentTitle: 'Associate Consultant',
    education: [['2017 - 2021', 'Bachelor of Technology in IT']],
    experienceTotal: ' 3 + years of experience',
    experienceDetail: [
      [
        '2021 - Present',
        'Associate Consultant',
        'Ryde Consulting',
        'Worked on "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut  ',
      ],
    ],
  },
];
export default teamData;
