import React from 'react';
import './Footer.css';
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { BsTwitter, BsFacebook } from 'react-icons/bs';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import logo from '../../images/logo.svg';
function Footer(props) {
  return (
    <div className='footer-wrapper'>
      <div className='footer'>
        <div className='logo-col'>
          <div className='logo-item'>
            <img src={logo} className='filter-invert ' />

            <h2 className='logo'>Ryde</h2>
          </div>
          <div className='tagline'>
            <br></br>
            <h3>
              Providing <span style={{ fontSize: '3rem' }}>Quality</span>{' '}
              Consulting Services
            </h3>
          </div>
        </div>
        <div className='company-col'>
          {/* <ul>
            <h2>Company</h2>
            <li>
              <a href=''>home</a>
            </li>

            <li>
              <a href=''>projects</a>
            </li>
            <li>
              <a href=''>team</a>
            </li>
            <li>
              <a href=''>about</a>
            </li>
            <li>
              <a href=''>contact</a>
            </li>
           
          </ul> */}
          <ul>
            <h2>Company</h2>
            <NavLink to='/home' activeClassName='active'>
              <li>Home</li>
            </NavLink>

            <NavLink
              to={{
                pathname: '/projectinfo/1',
              }}
              activeClassName='active'
            >
              <li>Projects</li>
            </NavLink>

            {/* <NavLink to='/team/1' activeClassName='active'>
              <li>Team</li>
            </NavLink>

            <NavLink to='/about' activeClassName='active'>
              <li>About</li>
            </NavLink> */}

            <NavLink to='/contact' activeClassName='active'>
              <li>Contact</li>
            </NavLink>
          </ul>
        </div>
        {/* <div className='terms-col'>
          <ul>
            <h2>Terms</h2>
            <NavLink to='/faq' activeClassName='active'>
              <li>FAQ</li>
            </NavLink>
            <NavLink to='/privacy' activeClassName='active'>
              <li>privacy</li>
            </NavLink>
          </ul>
        </div> */}
        <div className='follow-us-col'>
          <h2>Follow us</h2>
          <div className='social-links'>
            <a>
              <FaFacebookF color='#7420d5' />
            </a>
            <a>
              <FaTwitter color='#7420d5' />
            </a>
            <a>
              <FaInstagram color='#7420d5' />
            </a>
          </div>
        </div>
      </div>
      <div className='copyright'>© Ryde. All rights reserved.</div>
    </div>
  );
}

export default Footer;
